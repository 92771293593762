.footer{
    width: 100%;
    margin-top: 5rem;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.footer h2{
    margin-right: 2rem;
}

.footer-socials{
    margin: 2rem 0 1rem;
}

.footer-copyright{
    display: flex;
    flex-direction: row;
}