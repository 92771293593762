.button-wrapper{
    font-size: 2rem;
    margin: 2rem;
    border: none;
    border-radius: .5rem;
    padding: 1rem;
    color: white;
    cursor: pointer;
    font-family: var(--font-primary);
    
}