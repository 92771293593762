
.contact{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    
    
}

.contact input {
    border: 0.2rem solid var(--secondary);
    border-radius: .5rem;
    line-height: 5rem;
    font-size: 2rem;
    margin: 1rem 0;

}

.contact textarea{
    border: 0.2rem solid var(--secondary);
    border-radius: .5rem;
    font-size: 2rem;
    margin: 1rem 0;

}

.contact-submit{
    font-size: 2rem;
    margin: 2rem;
    border: none;
    border-radius: .5rem;
    padding: 1rem;
    color: white;
    cursor: pointer;
    font-family: var(--font-primary);
}