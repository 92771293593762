.about{
    padding: 20rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.about .span-name{
    color: var(--primary);
}

@media only screen and (min-width: 1366px) {
    /*for larger desktop monitors */
    .about-description{
        max-width: 50%;
    }
}
