.App
{

    /* ff 3.6+ */
    background:-moz-linear-gradient(111deg, rgba(0, 0, 0, 1) 0%, rgba(8, 7, 90, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(111deg, rgba(0, 0, 0, 1) 0%, rgba(8, 7, 90, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(111deg, rgba(0, 0, 0, 1) 0%, rgba(8, 7, 90, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#08075A', endColorstr='#000000', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(111deg, rgba(0, 0, 0, 1) 0%, rgba(8, 7, 90, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(111deg, rgba(0, 0, 0, 1) 0%, rgba(8, 7, 90, 1) 100%);

}