nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10.5rem;
    position: fixed;
    top: 0;

    
}

nav > .logo {
    margin: 1rem;
}

nav > .menu {
    margin: 1rem;
}

nav .menu-list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .menu-item {
    margin: 2rem;
}

nav .menu-item a {
    font-size: 2rem;
    background-color: transparent;
    cursor: pointer;
}

nav .menu-item a:visited,
a:hover,
a:active {
    color: white;
}

nav .logo {
}
